<template>
    <el-select
        size="small"
        :placeholder=placeholder
        :class="inputClassName"
        :disabled="disable"
        v-model="dropdownValue"
        @change="getDropdownData"
        filterable clearable
        >
        <el-option
            v-for="item in dropdownList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
    </el-select>
</template>

<script>
import { fGetDataInfoByDataCode} from "@/utils/utilsset";

export default {
    name: "di-dropdown",
    props:{
        //这是父组件传入的内容
        //字典类型编码
        dataCode:{
            type: String
        },
        //class名称，用于控制样式
        inputClassName: {
            type: String
        },
        //输入的默认值
        defaultInput: {
            type: String
        },
        placeholder:{
            type:String
        },
        opts:{
            type:Object
        }
    },
    data() {
        return {
            disable:false,
            dropdownValue:'',
            dropdownList:[],
        };
    },
    created() {
        //加载数据字典
        this.loadDataInfo();
    },
    mounted() {
    },
    methods: {
        //加载数据字典
         loadDataInfo(){

             fGetDataInfoByDataCode(this.$axios,this.dataCode).then((res)=>{
                 //console.log(res);
                 this.dropdownList=[];
                 this.dropdownList = res;
                 // this.dropdownValue=this.defaultInput;

                 if(this.opts){
                     this.disable=this.opts.disable
                     if(this.opts.vmodel){
                         // console.log(this.opts.vmodel);
                         this.dropdownValue=JSON.stringify(this.opts.vmodel);
                     }
                     else
                         this.dropdownValue=''
                 }
             })

        },
        //返回值
        getDropdownData(){
            //emit的英文原意为触发, 第二个参数后面都是传给这个方法的参数
            //console.log('emit');
            // console.log(this.dropdownValue);
            this.$emit('getData', this.dropdownValue, this.dropdownList);
        },
    },
    watch: {
        //监听父组件的消息
        opts: {
            handler: function (newV, oldV) {
                // console.log(newV);
                this.opts=newV
                this.loadDataInfo()
            },
            deep: true,//深度监听
        }
    }
}
</script>

<style scoped>
</style>
